import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    questions: {
        filler: '',
        userID: '',
        subjectID: '',
        age: '',
        presence: '',
        note: '',
        Q1: '', 
        Q2: '', 
        Q3: '', 
        Q3A: '',
        Q3B: '',
        Q4: '',
        Q4A: '',
        Q4B: '',
        Q4C: '',
        Q5: '',
        Q5A: '',
        Q5B: '',
        score: 'Not enough information',
        disagree: '',
    },
    loading: 'idle',
}

const GOOGLE_SCRIPT_URL = 'https://script.google.com/macros/s/AKfycbz62fkiGjW8GCgvbDkPrz4h_826eOZKMFACoPNI85OHnRM7a0qVcNWu7jZdD3Cf-__d-w/exec'

export const submitResearchQuestions = createAsyncThunk(
    'researchQuestions/submitResearchQuestions',
    async ({payload, another}) => {
        let modifiedPayload = {...payload};
        for (let key in modifiedPayload) {
            if (modifiedPayload[key] === 'true') {
                modifiedPayload[key] = '1';
            } else if (modifiedPayload[key] === 'false') {
                modifiedPayload[key] = '0';
            }
        }
        const data = JSON.stringify(Object.keys(modifiedPayload).map(key => modifiedPayload[key]));
        console.log('Attempting to Post Data: ' + data );
        const response = await axios({
            method: 'post',
            url: GOOGLE_SCRIPT_URL,
            data,
        })
        .then(response => {
            console.log('Success: ' + response.data);
            return another
        })
        .catch(error => {
            return error
        })
        return response
    }
)


const researchQuestionsSlice = createSlice({
    name: 'researchQuestions',
    initialState,
    reducers: {
        setQuestion: (state, action) => {
            state.questions[action.payload.index] = action.payload.value;
        },
        resetQuestions: (state) => {
            return initialState
        }, //REFACTOR INTO A CHECK ARRAY SYSTEM (each condition is its own "check" function)
        calculateScore: (state) => {
            if (state.questions.presence === '1' || state.questions.presence === '0') {
                if (state.questions.note === '1'){
                    state.questions.score = '1'
                } else if (state.questions.note === '2') {
                     state.questions.score = '1'
                } else if (state.questions.note === '3') {
                    if (state.questions.Q1  !== '') {
                        if (state.questions.Q2 !== '' && state.questions.Q2 !== 'select') {
                            if (state.questions.Q3 !== '') {
                                  if (state.questions.Q3B === '1') {
                                    if (state.questions.presence === '0') {
                                         state.questions.score = '4'
                                    } else if (state.questions.presence === '1') {
                                         state.questions.score = '5'
                                    } else {
                                        state.questions.score = 'Not enough information'
                                    }
                                  } else if (state.questions.Q3B === '0') {
                                    if (state.questions.presence === '0') {
                                         state.questions.score = '2'
                                    } else if (state.questions.presence === '1') {
                                         state.questions.score = '5'
                                    } else {
                                        state.questions.score = 'Not enough information'
                                    }
                                  } else if (state.questions.Q4 === '1') {
                                    if (state.questions.Q4B !== '' && state.questions.Q4B !== 'select') {
                                        console.log(JSON.stringify(state.questions))
                                        if (state.questions.Q4A === '1') {
                                            if (state.questions.presence === '0') {
                                                 state.questions.score = '2'
                                            } else if (state.questions.presence === '1') {
                                                 state.questions.score = '5'
                                            } else {
                                                state.questions.score = 'Not enough information'
                                            }
                                        } else if (state.questions.Q4B !== '' && state.questions.Q4B !== 'select') {
                                            if (state.questions.Q4C === '1') {
                                                if (state.questions.presence === '0') {
                                                     state.questions.score = '4'
                                                } else if (state.questions.presence === '1') {
                                                     state.questions.score = '5'
                                                } else {
                                                    state.questions.score = 'Not enough information'
                                                }
                                            } else if (state.questions.Q4C === '2') {
                                                state.questions.score = '5'
                                            } else if (state.questions.Q4C === '3') {
                                                if (state.questions.presence === '0') {
                                                     state.questions.score = '3'
                                                } else if (state.questions.presence === '1') {
                                                     state.questions.score = '5'
                                                } else {
                                                    state.questions.score = 'Not enough information'
                                                }
                                            } else if (state.questions.Q4C === '4') {
                                                if (state.questions.presence === '0') {
                                                     state.questions.score = '4'
                                                } else if (state.questions.presence === '1') {
                                                     state.questions.score = '5'
                                                } else {
                                                    state.questions.score = 'Not enough information'
                                                }
                                            } else if (state.questions.Q4C === '5') {
                                                 state.questions.score = '5'
                                            } else {
                                                state.questions.score = 'Not enough information'
                                            }
                                        } else {
                                            state.questions.score = 'Not enough information'
                                        }
                                    } else {
                                        state.questions.score = 'Not enough information'
                                    }
                                  } else if (state.questions.Q4 === '0') {
                                    if (state.questions.Q5 === '0') {
                                        if (state.questions.Q5A === '1' || state.questions.Q5A === '2' || state.questions.Q5A === '4') {
                                            if (state.questions.Q5B !== '') {
                                                if (state.questions.presence === '0') {
                                                     state.questions.score = '2'
                                                } else if (state.questions.presence === '1') {
                                                     state.questions.score = '5'
                                                } else {
                                                    state.questions.score = 'Not enough information'
                                                }
                                            } else {
                                                state.questions.score = 'Not enough information'
                                            }
                                        } else if (state.questions.Q5A === '3' || state.questions.Q5A === '5') {
                                            if (state.questions.Q5B === '1') {
                                                if (state.questions.presence === '0') {
                                                     state.questions.score = '3'
                                                } else if (state.questions.presence === '1') {
                                                     state.questions.score = '5'
                                                } else {
                                                    state.questions.score = 'Not enough information'
                                                }
                                            } else if (state.questions.Q5B === '0') {
                                                if (state.questions.presence === '0') {
                                                     state.questions.score = '2'
                                                } else if (state.questions.presence === '1') {
                                                     state.questions.score = '5'
                                                } else {
                                                    state.questions.score = 'Not enough information'
                                                }
                                            } else {
                                                state.questions.score = 'Not enough information'
                                            }
                                        } else {
                                            state.questions.score = 'Not enough information'
                                        }
                                    } else if (state.questions.Q5 === '1') {
                                        if (state.questions.Q5A !== '') {
                                            if (state.questions.Q5B !== '') {
                                                if (state.questions.presence === '0') {
                                                     state.questions.score = '3'
                                                } else if (state.questions.presence === '1') {
                                                     state.questions.score = '5'
                                                } else {
                                                    state.questions.score = 'Not enough information'
                                                }
                                            } else {
                                                state.questions.score = 'Not enough information'
                                            }
                                        } else {
                                            state.questions.score = 'Not enough information'
                                        }
                                    } else {
                                        state.questions.score = 'Not enough information'
                                    }
                                  } else {
                                    state.questions.score = 'Not enough information'
                                  }
                            } else {
                                state.questions.score = 'Not enough information'
                            }
                        } else {
                            state.questions.score = 'Not enough information'
                        }
                    } else {
                        state.questions.score = 'Not enough information'
                    }
                } else if (state.questions.note === '4') {
                    if (state.questions.presence === '0') {
                         state.questions.score = '2'
                    } else if (state.questions.presence === '1') {
                         state.questions.score = '5'
                    } else {
                        state.questions.score = 'Not enough information'
                    }
                } else if (state.questions.note === '5') {
                    if (state.questions.presence === '0') {
                         state.questions.score = '2'
                    } else if (state.questions.presence === '1') {
                         state.questions.score = '5'
                    } else {
                        state.questions.score = 'Not enough information'
                    }
                } else if (state.questions.note === '6') {
                     state.questions.score = 'n/a (peritoneal inclusion cysts do not get an O-RADS MRI score)'
                } else {
                    state.questions.score = 'Not enough information'
                }
            } else {
                state.questions.score = 'Not enough information'
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submitResearchQuestions.pending, (state) => {
            state.loading = 'pending';
        })
        .addCase(submitResearchQuestions.fulfilled, (state, action) => {
            state.loading = 'idle';
        })  
        .addCase(submitResearchQuestions.rejected, (state) => {
            state.loading = 'error';
        });
    }
});

export const { setQuestion, resetQuestions, calculateScore } = researchQuestionsSlice.actions;
export const selectResearchQuestions = state => state.researchQuestions;
export default researchQuestionsSlice.reducer;